@import '../../../../scss/theme-bootstrap';

.gnav-links {
  @include breakpoint($landscape-only) {
    height: 100%;
  }
  &__header {
    @include gnav-section-title;
    position: relative;
    display: none;
    padding-bottom: 2px;
    padding: 5px $gnav-mobile-hpad--outer;
    @include breakpoint($landscape-only) {
      display: flex;
      padding: 5px 0;
    }
  }
  &__header-label {
    display: block;
    width: 100%;
    line-height: $gnav-mobile-link-height;
    padding-#{$ldirection}: $gnav-mobile-hpad--outer;
    @include breakpoint($landscape-only) {
      display: inline-block;
      padding-#{$ldirection}: 0;
      line-height: inherit;
      width: auto;
    }
  }
  &__mobile-section-toggle {
    opacity: 1;
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    @include breakpoint($landscape-only) {
      display: none;
    }
  }
  &__content {
    .gnav-links__header ~ & {
      padding: 0;
      @include breakpoint($landscape-only) {
        background-color: transparent;
        height: calc(100% - #{$gnav-mobile-link-height});
      }
    }
  }
  &__content-links {
    .gnav-links--columns-break & {
      @include breakpoint($landscape-only) {
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
      @for $i from 1 through 15 {
        :not(.gnav-links__link--break).gnav-links__link:nth-child(#{$i}) {
          @include breakpoint($landscape-only) {
            order: $i;
          }
        }
        &--break-afer-#{$i} .gnav-links__link--break {
          @include breakpoint($landscape-only) {
            flex-basis: 100%;
            width: 0;
            overflow: hidden;
            order: $i;
          }
        }
      }
    }
  }
  &__link {
    color: $color-white;
    padding: 5px $gnav-mobile-hpad--outer;
    @include breakpoint($landscape-only) {
      color: $color-white-op50;
      padding: 5px 0;
    }
    .gnav-links--columns-break & {
      @include breakpoint($landscape-only) {
        width: 50%;
      }
    }
    &.link-with-event {
      min-height: 29.5px;
      .LPMcontainer {
        visibility: visible;
        /* stylelint-disable-next-line declaration-no-important */
        margin: 0 !important;
        & ~ .gnav-link {
          display: none;
        }
      }
      .gnav-links__link-lp-event:hover {
        color: $color-white;
      }
    }
  }
  &__mobile-icon {
    display: inline-block;
    height: $gnav-icon--inline;
    width: $gnav-icon--inline;
    &.icon--minus {
      display: none;
    }
  }
  &__header-link,
  .gnav-link {
    @include gnav-text-link();
    line-height: get-line-height(15px, 19px);
  }
}
